import { FC } from 'react';
import './widgets/integration.css';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import WhatsAppTemplateForm from './widgets/interaction-template';

export interface FormTemplateProps  {
}

const FormTemplate: FC<FormTemplateProps>  = () => {

  
  return (
    <MainLayout>
    <PageHeader
      title='Plantillas'
      showFilters={false}
      showRangeInput={false}
      showMainFilters={false}
    />
    <div className="integration-explorer">
      <div className="integration-list">
        <WhatsAppTemplateForm/>
      </div>
    </div>
  </MainLayout>
  );
}

export { FormTemplate }
export default FormTemplate;