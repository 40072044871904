import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectSlice as selectStudiesSlice } from "../../../store/slices/studies";
import {
  integrationService,
  Integrations,
} from "../../../services/integrations";
import { IntegrationCard } from "./integration-card";
import { useHistory } from "react-router-dom";
import { setAvailableIntegrations, setIntegration } from "../../../store/slices/integrations";
import { Modal } from "../../../common/components/modal";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: {
      init: (config: any) => void;
      login: (callback: (response: any) => void, options: any) => void;
    };
    fbq: (...args: any[]) => void;
  }
}

interface WhatsAppState {
  authResponse?: any;
  data?: any;
  token?: string;
  userId?: string; 
  config?:any;
  id?: string;
}

const Integration = () => {
  const [integrations, setIntegrations] = useState<Integrations>([]);
  const [loading, setLoading] = useState(true);
  const studies = useAppSelector(selectStudiesSlice);
  const [whatsapp, setWhatsapp] = useState<WhatsAppState>({});
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [textModal, setTextModal] = useState<string>("");
  const [idIntegration, setIdIntegration] = useState<string>("");

  function onRedirect (path: string, integrationId: string ) {
    dispatch(setIntegration(integrationId));
    history.push(path);
  }

  async function onGetUser (id: string) {
    try {
      setLoading(true);
      const user:any = await integrationService.getUser(id);
      if(user && user.updatedIntegration){
        setTextModal("Usuario obtenido correctamente");
        setOpenModal(true);
      }
    } catch (error) {
      setTextModal(
        `Error: ${error}`
      );
      setOpenModal(true);
    }
    finally {
      setLoading(false);
    }
  }

  async function onGetPhone (id: string) {
    try {
      setLoading(true);
      const user:any = await integrationService.getPhone(id);
      if(user && user.updatedIntegration){
        setTextModal("Teléfono obtenido correctamente");
        setOpenModal(true);
      }
    } catch (error) {
      setTextModal(
        `Error: ${error}`
      );
      setOpenModal(true);
    }
    finally {
      setLoading(false);
    }
  }

  async function loadIntegrations(): Promise<void> {
    setLoading(true);
    try {
      const integrations = await integrationService.getIntegrations(
        studies.selected?._id, {archived:false}
      );
      if (integrations) {
        setIntegrations(integrations);
        dispatch(setAvailableIntegrations(integrations));
        integrations.forEach(async (integration) => {
          if(!integration.config?.token) {
            const response = await integrationService.getToken(
              integration._id
            );
            console.log(response);
            if (typeof response === "boolean"){
              setTextModal("Código de acceso obtenido correctamente");
              setOpenModal(true);
            } else {
              setTextModal(
                `Error: ${response}`
              );
              setOpenModal(true);
            }
            console.log(response)
          }
        });
      }
    } catch (err) {
      setTextModal(
        `Error: ${err}`
      );
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadIntegrations();
  }, [studies.selected]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_ID,
        cookie: true,
        xfbml: true,
        version: 'v21.0', 
      });
    };

    (function (d: Document, s: string, id: string) {
      const js = document.createElement(s) as HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js.id = id;
      js.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js'); 
      fjs.parentNode?.insertBefore(js, fjs); 
    })(document, 'script', 'facebook-jssdk');

    const sessionInfoListener = (event: MessageEvent) => {
      if (!event.origin || !event.origin.endsWith("facebook.com")) {
        return;
      }

      try {
        console.log('event ', event)
        const data = JSON.parse(event.data);
        console.log('data ', data)
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log("Phone number ID: ", phone_number_id, " WhatsApp business account ID: ", waba_id);
            setWhatsapp((prev) => ({ ...prev, data: data.data }));
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("Error: ", error_message);
          } else {
            const { current_step } = data.data;
            console.warn("Canceled at: ", current_step);
          }
        }
      } catch {
        console.log('Non-JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);

    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };

  }, []);

  const launchWhatsAppSignup = () => {
    window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: process.env.REACT_APP_FACEBOOK_ID, feature: 'whatsapp_embedded_signup' });

    window.FB.login(async (response) => {
      if (response.authResponse) {
        const code = response.authResponse.code;
        console.log('Código de autorización:', code);
        console.log('authResponse:',  response.authResponse);
        console.log('status:',  response.status);
        setWhatsapp((prev) => ({ ...prev, authResponse: response.authResponse }));
        const result = await integrationService.createIntegration({
          name: `whatsapp_${studies.selected?._id}`,
          studyId: studies.selected?._id,
          config: {authResponse: response.authResponse}
        });
        if (result) {
          console.log('creado');
          loadIntegrations();
        }
      } else {
        console.log('Usuario canceló el inicio de sesión o no autorizó completamente.');
      }
    }, {
      config_id: process.env.REACT_APP_CONFIG_FACEBOOK_ID,
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        setup: {
        },
      },
    });
  };

  async function clickModal() {
    if (textModal.includes("correctamente")) {
      window.location.reload();
    } else if(textModal.includes("quieres eliminar tu cuenta")) {
      if(idIntegration) {
        try {
          setLoading(true);
          const response = await integrationService.deleteIntegration(idIntegration);
          if(response){
            setTextModal("Cuenta eliminada correctamente. Para desvincular en WhatsApp Manager, inicia sesión y ve a la sección de partners. Busca el nombre de Alchimia y haz clic en la opción para desvincularlo. Confirma la acción y listo, nuestro partner ya no estará vinculado");
            setOpenModal(true);
          }
        } catch (error) {
          setTextModal(
            `Error: ${error}`
          );
          setOpenModal(true);
        }
        finally {
          setLoading(false);
        }
      }
    } else {
      setOpenModal(false);
    }
  }

  async function onDelete (id: string) {
    setTextModal("¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer");
    setOpenModal(true);
    setIdIntegration(id);
  }

  return (
    <div className="integration-explorer">
      <Modal
        open={openModal}
        showLoadingAnimation={loading}
        text={textModal}
        onClick={() => clickModal()}
      />
      <div className="integration-list">
        {integrations?.map((integration) => (
          <IntegrationCard
            key={integration._id}
            {...integration}
            onRedirect={onRedirect}
            onGetUser={onGetUser}
            onGetPhone= {onGetPhone}
            onDelete ={onDelete}
          />
        ))}
        {loading && <p>Cargando datos</p>}
        {integrations && integrations?.length < 1 && (
          <p style={{ fontSize: "18px" }}>
            No existen integraciones configuradas
          </p>
        )}
      </div>
      <br />
      {integrations && integrations?.length < 1 && (<button onClick={launchWhatsAppSignup} style={{backgroundColor: "#1877f2", border: 0,borderRadius: '4px', color: '#fff',cursor: "pointer", padding: '10px'}}>Integrar Whatsapp con Facebook</button>)}
    </div>
  );
};

export default Integration;
export { Integration };
