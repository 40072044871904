import { FC, useEffect } from 'react';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components'
import { Integration } from './widgets';
import { useViewConfig } from '../../common/hooks/use-view-config';
import { ViewBanner } from '../../common/components/view-banner';

interface IntegrationsScreenProps {
};

const IntegrationsScreen: FC<IntegrationsScreenProps> = () => {
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();

  useEffect(() => {
    loadViewConfig();
  }, [loadViewConfig]);

  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
        ) : viewConfig ? (
          <>
            {viewConfig.infoMessage ? (
              <ViewBanner {...viewConfig.infoMessage} />
            ) : null}
            <PageHeader
              title={viewConfig.viewOptions.pageTitle}
              showFilters={false}
              showRangeInput={false}
              showMainFilters={false}
            />
            <Integration />
          </>
        ) : (
          <div>Ocurrió un problema al cargar la vista de integraciones.</div>
        )
      }
    </MainLayout>
  );
}

export default IntegrationsScreen;
export {
  IntegrationsScreen,
};