import { constants, ServerResponse } from '../../utils';
import { storage } from '../';
import { Integration, Integrations } from './integration.model';


async function getIntegrations (studyId?: string, conditions?: any): Promise<Integrations> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/integration`;
  let fullConditions = {};
  if (conditions) {
    fullConditions = conditions;
  }
  if (studyId) {
    fullConditions = { ...fullConditions, studyId }
  }
  if (conditions || studyId) {
    URL += `?conditions=${JSON.stringify(fullConditions)}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Integrations> = await fetchResponse.json();
  return response.data;
}

async function getToken (id:string): Promise<boolean | string> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/integration/setup-whatsapp/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<boolean | string> = await fetchResponse.json();
  return response.data;
}

async function getUser (id:string): Promise<boolean | string> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/integration/setup-user/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<boolean | string> = await fetchResponse.json();
  return response.data;
}

async function getPhone (id:string): Promise<boolean | string> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/integration/setup-phone/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<boolean | string> = await fetchResponse.json();
  return response.data;
}

async function createIntegration (data: any, conditions?: any): Promise<boolean> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  let URL = `${constants.API_URL}/integration`;
  if (conditions) {
    URL += `?conditions=${JSON.stringify(conditions)}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Integration> = await fetchResponse.json();
  const message = response.data;
  if (!message) throw new Error('No integration found')
  return true;
}

async function updateIntegration (id:string, data: any): Promise<boolean> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  let URL = `${constants.API_URL}/integration/${id}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Integration> = await fetchResponse.json();
  const message = response.data;
  if (!message) throw new Error('No integration found')
  return true;
}

async function deleteIntegration (id:string): Promise<boolean> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${constants.API_URL}/integration/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Integration> = await fetchResponse.json();
  const message = response.data;
  if (!message) throw new Error('No integration found')
  return true;
}

export {
  getIntegrations,
  createIntegration,
  updateIntegration,
  getToken,
  getUser,
  getPhone,
  deleteIntegration
}