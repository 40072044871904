import { FC, useEffect, useState } from 'react';
import './widgets/integration.css';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import { facebookService, Templates } from '../../services/facebook';
import { translateError } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSliceIntegrations } from '../../store/slices/integrations';
import { TemplateCard } from './widgets/template-card';
import { useHistory } from 'react-router-dom';
import { setAvailableTemplates, setTemplate } from '../../store/slices/templates';

export interface TemplateExplorerProps  {
}

const TemplatesExplorer: FC<TemplateExplorerProps>  = () => {
  const [templates, setTemplates] = useState<Templates>([]);
  const [loading, setLoading] = useState(true);
  const integrations = useAppSelector(selectSliceIntegrations);
  const dispatch = useAppDispatch();
  const history = useHistory();

  async function loadTemplates(): Promise<void> {
    setLoading(true);
    try {
      if(integrations.selected?.userId){
      const templates = await facebookService.getMessageTemplates(
        integrations.selected?.userId, integrations.selected?.config?.token
      );
      if (templates) {
        setTemplates(templates);
        dispatch(setAvailableTemplates(templates));
      }
    }
    } catch (err) {
      const error = translateError(err);
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTemplates();
  }, [integrations.selected]);

  function onRedirect (path: string, templateId: string ) {
    dispatch(setTemplate(templateId));
    history.push(path);
  }
  
  return (
    <MainLayout>
    <PageHeader
      title='Plantillas'
      showFilters={false}
      showRangeInput={false}
      showMainFilters={false}
      actionLabel='Crear nueva plantilla'
      action={() => history.push('/create/template')}
    />
    <div className="integration-explorer">
      <div className="integration-list">
        {templates?.map((template) => (
          <TemplateCard
            key={template.id}
            {...template}
            onRedirect={onRedirect}
          />
        ))}
        {loading && <p>Cargando datos</p>}
        {templates && templates?.length < 1 && <p style={{fontSize: '18px'}}>No existen plantillas creadas</p>} 
      </div>
    </div>
  </MainLayout>
  );
}

export { TemplatesExplorer }
export default TemplatesExplorer;