import { FacebookResponse, FacebookResponseCreate, FacebookResponseUpdate, Templates } from "./facebook.model";

async function getMessageTemplates (userId: string, accessToken: string): Promise<Templates> {
  try {
    const API_URL = `https://graph.facebook.com/v21.0/${userId}/message_templates`;
    const fetchOpts: RequestInit = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    };

    const fetchResponse: Response = await fetch(API_URL, fetchOpts);
    const response: FacebookResponse<Templates> = await fetchResponse.json();

    if (!response.data) {
      throw new Error(`Error: ${response.error?.message}`);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching message templates:', error);
    throw error; 
  }
};

async function CreateMessageTemplate (userId: string, accessToken: string, template: any): Promise<FacebookResponseCreate> {
  try {
    const API_URL = `https://graph.facebook.com/v21.0/${userId}/message_templates`;
    const fetchOpts: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template)
    };

    const fetchResponse: Response = await fetch(API_URL, fetchOpts);
    const response: FacebookResponseCreate= await fetchResponse.json();

    return response;
  } catch (error) {
    console.error('Error fetching message templates:', error);
    throw error; 
  }
};

async function UpdateMessageTemplate (accessToken: string, id:string, components: any): Promise<FacebookResponseUpdate> {
  try {
    const API_URL = `https://graph.facebook.com/v21.0/${id}`;
    const fetchOpts: RequestInit = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(components)
    };

    const fetchResponse: Response = await fetch(API_URL, fetchOpts);
    const response: FacebookResponseUpdate = await fetchResponse.json();

    return response;
  } catch (error) {
    console.error('Error fetching message templates:', error);
    throw error; 
  }
};

export {
  getMessageTemplates,
  CreateMessageTemplate,
  UpdateMessageTemplate
}