import React, { FC } from 'react';
import {
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AnalyticsTrendChart, LightChart, MetricType } from '../../../services/analytics';
import { toNumber, toPercentage } from '../../../utils';

type DisplayField = keyof Omit<LightChart, 'value'> | MetricType;
type DisplayType = 'absolute' | 'percentage';
type FormatMaker = (displayField: DisplayField, displayType: DisplayType) => (value: number, name: any, props: any) => any[];

interface AnalyticsLineChartProps {
  lines: AnalyticsTrendChart[] | undefined;
  displayField: DisplayField;
  displayType: DisplayType;
}

/*const formatLegend = (value: string, entry: any) => {
  const { color, payload: { display, percentage, result } } = entry;
  return (
    <div style={{ display: 'inline-flex', width: '160px', flexDirection: 'row', justifyContent: 'space-between' }}>
      <span style={{ color: 'black', fontSize: '10px' }}>{`${_t(display)}`}</span>
      <span style={{ color: 'black', fontSize: '12px' }}>{`${toNumber(result)} (${toPercentage(percentage)})`}</span>
    </div>
  )
  
};*/

const _f: any = {
  absolute: toNumber,
  percentage: toPercentage,
}

const makeFormatTooltip: FormatMaker = (displayField, displayType) => (value, name, props) => {
  if (displayField === 'n') {
    return [`${_f[displayType](value)}`, props.payload?.display]
  }
  return [`${_f[displayType](value)} (${props.payload?.n} res.)`, props.payload?.display]
}

const AnalyticsLineChart: FC<AnalyticsLineChartProps> = ({
  lines,
  displayField,
  displayType,
}) => {

  let data: any[] = [];
  if (lines) {
    data = lines.map(({ value: { display, value: date }, n, result, chart }) => {
      let line: any = { display, date, n, result };
      chart.forEach(c => {
        line[c.value.value] = c.result;
      })
      return line;
    });
  }

  return (
    <ResponsiveContainer
      width="64%"
      height={200}
    >
      <LineChart
        width={400}
        height={200}
        data={data}
      >
        <Tooltip
          cursor={{ stroke: "#DFE2E3" }}
          offset={40}
          separator=": "
          labelFormatter={() => ``}
          formatter={makeFormatTooltip(displayField, displayType)}
        />
        {/* <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          formatter={formatLegend}
        /> */}
        <XAxis
          padding={{ left: 24, right: 24 }}
          dataKey="display"
          tickSize={4}
          angle={-45}
          interval="preserveStartEnd"
          tick={
            ({ x, y, payload }) => (
              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={12} textAnchor="middle" fontSize={10} fill="#333">
                  {payload.value}
                </text>
              </g>
            )
          }
          // axisLine={{ stroke: 'red', strokeWidth: 1, strokeOpacity: 1, strokeDasharray: '0 0' }}
          // tickFormatter={(t: any, i: number) => { console.log(t); return '';} }
        />
        <CartesianGrid
          stroke="#DFE2E3"
          strokeDasharray="4 4"
          vertical={false}
        />
        <YAxis hide={true} />
        <Line
          isAnimationActive={false}
          dataKey={displayField}
          fill="#0388D1"
          dot={{ strokeWidth: 1 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export {
  AnalyticsLineChart,
}