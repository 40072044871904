import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Template } from '../../services/facebook';

// Define a type for the slice state
interface TemplatesState {
  available: Template[];
  selected?: Template;
}

// Define the initial state using that type
const initialState: TemplatesState = {
  available: [],
};

export const templatesSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAvailableTemplates: (state, action: PayloadAction<Template[]>) => {
      state.available = action.payload;
    },
    setTemplate: (state, action: PayloadAction<string>) => {
      const nextIntegration = state.available.find(cp => cp.id === action.payload);
      if (nextIntegration) {
        state.selected = nextIntegration;
      }
    },
  },
})
export const { setAvailableTemplates, setTemplate } = templatesSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectSliceTemplates = (state: RootState) => state.templates;
export const selectTemplates = (state: RootState) => state.templates.available;
export default templatesSlice.reducer;