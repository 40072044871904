import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Integration } from '../../services/integrations';

// Define a type for the slice state
interface IntegrationsState {
  available: Integration[];
  selected?: Integration;
}

// Define the initial state using that type
const initialState: IntegrationsState = {
  available: [],
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAvailableIntegrations: (state, action: PayloadAction<Integration[]>) => {
      state.available = action.payload;
    },
    setIntegration: (state, action: PayloadAction<string>) => {
      const nextIntegration = state.available.find(cp => cp._id === action.payload);
      if (nextIntegration) {
        state.selected = nextIntegration;
      }
    },
  },
})
export const { setAvailableIntegrations, setIntegration } = integrationsSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectSliceIntegrations = (state: RootState) => state.integrations;
export const selectIntegrations = (state: RootState) => state.integrations.available;
export default integrationsSlice.reducer;