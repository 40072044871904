import { FC } from 'react';
import './integration.css';
import { Integration } from '../../../services/integrations';

export interface IntegrationProps extends Integration {
  onRedirect: any,
  onGetUser: any,
  onGetPhone: any,
  onDelete: any
}

const IntegrationCard: FC<IntegrationProps> = ({
  _id,
  name,
  config,
  userId,
  onRedirect, 
  onGetUser, 
  onGetPhone, 
  onDelete
}) => {

  
  return (
  <div className="integration-card">
    <p className="integration-title">{name}</p>
    <br />
    {userId && <p className="integration-text">Id de usuario: {userId}</p> }
    {config.data && config.data.length > 0 &&(<div>
      <p className="integration-text">Nombre verificado: {config.data[0].verified_name}</p>
      <p className="integration-text">Número de teléfono: {config.data[0].display_phone_number}</p>
      <p className="integration-text">Id del teléfono: {config.data[0].id}</p>
    </div>)}
    <div className='integration-actions-container'>
      {!userId && <button className="integration-button" onClick={() => onGetUser(_id)}>Obtener Usuario</button>}
      {!config.data && <button className="integration-button" onClick={() => onGetPhone(_id)}>Obtener teléfono</button>}
      {userId && <button className="integration-button" onClick={() => onRedirect('/templates', _id)}>Plantillas</button>}
      <button className="integration-button" style={{background: 'red'}} onClick={() => onDelete(_id)}>Eliminar cuenta</button>
    </div>
  </div>
)};

export {
  IntegrationCard,
}