import { FC } from 'react';
import './integration.css';
import { Template } from '../../../services/facebook';

export interface TemplateProps extends Template {
  onRedirect: any
}

const TemplateCard: FC<TemplateProps> = ({
  id,
  name,
  status,
  components,
  onRedirect
}) => {

  
  return (
  <div className="integration-card">
    <div className="message-status"><b>{status === "APPROVED" ? "Aprobado" : status === "PENDING" ? "Pendiente" : status === "REJECTED" ? "Rechazado" : status}</b></div>
    <p className="integration-title">{name}</p>
      {components?.map((component,idx) => (
          component.type !== "BUTTONS" 
            ? <p key={idx} className="integration-text">{component.type}: {component?.text}</p> 
            : <div key={idx}>
                <br />
                <p className="integration-text">{component.type}:</p> 
                {component.buttons?.map((button:any,indx) => (
                  <p key={indx} className="integration-text">{button.text}</p>
                ))}
              </div>
      ))}
    <br />
    <div className='integration-actions-container'>
      <button className="integration-button" onClick={() => onRedirect('/edit/template', id)}>Editar</button>
    </div>
  </div>
)};

export {
  TemplateCard,
}