import React, { FC } from 'react';
import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AnalyticsTrendChart, LightChart, MetricType } from '../../../services/analytics';
import { toNumber, toPercentage } from '../../../utils';

type DisplayField = keyof Omit<LightChart, 'value'> | MetricType;
type DisplayType = 'result' | 'count';

export interface LineConfig {
  field: string;
  color: string;
}

interface AnalyticsMultilineChartProps {
  lines: AnalyticsTrendChart[] | undefined;
  displayField: DisplayField;
  displayType: DisplayType;
}

function getColor (k: string): string {
  const PHASE_COLORS: any = {
    // Empresas
    'Access': '#1C917F',
    'Information Exchange': '#0085C8',
    'Momentum': '#3AD0E3',
    'Lost': '#FC7F02',
    'Kam': '#FFE05F',
    'KAM': '#FFE05F',
    // Agentes
    'Inicio': '#1C917F',
    'Durante': '#0085C8',
    'Post': '#3AD0E3',
    'Abandonadores': '#FC7F02',
  };
  return PHASE_COLORS[k] ?? k;
}

const AnalyticsMultilineChart: FC<AnalyticsMultilineChartProps> = ({
  lines,
  displayField,
  displayType,
}) => {

  let data: any[] = [];
  let linesToDisplay: any[] = [];
  if (lines && lines[0]) {
    linesToDisplay = lines.map(group => ({ field: group.value.display, color: getColor(group.value.display) }));
    data = lines[0].chart.map(date => {
      let period = {
        date: date.value.value,
        display: date.value.display,
      };
      lines.forEach(group => {
        const dataPoint: any = group.chart.find(d => d.value.value === date.value.value)
        if (!dataPoint) return;
        period = {
          ...period,
          [`label${group.value.display}`]: group.value.display,
          [`count${group.value.display}`]: dataPoint.n,
          [`result${group.value.display}`]: dataPoint.chart.find((subRes: any) => subRes.value.value === displayField)?.result,
        }
      })
      return period;
    })
  }

  // console.log('Multiline Data');
  // console.log(data);

  return (
    <ResponsiveContainer
      width="64%"
      height={240}
    >
      <LineChart
        width={400}
        height={240}
        data={data}
      >
        <Tooltip
          cursor={{ stroke: "#DFE2E3" }}
          position={{ x: 400, y: 0 }}
          separator=": "
          formatter={
            (value: number, name: string, props: any) => {
              const k = name.replace(displayType, '');
              const label = props?.payload?.[`label${k}`];
              const count = toNumber(props?.payload?.[`count${k}`]);
              const percentage = toPercentage(props?.payload?.[`result${k}`]);
              // return [`${count} (${ percentage})`, label];
              return [`${ percentage} (${count} res.)`, label];
            }
          }
          labelStyle={{ fontSize: 16 }}
          contentStyle={{ fill: '#000', color: '#000' }}
          itemStyle={{ fill: '#000', color: '#000', fontSize: 12, margin: 0, padding: 0 }}
          wrapperStyle={{ zIndex: 999999 }}
        />
        <XAxis
          padding={{ left: 24, right: 24 }}
          dataKey="display"
          tickSize={4}
          angle={-45}
          interval="preserveStartEnd"
          tick={
            ({ x, y, payload }) => (
              <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={12} textAnchor="middle" fontSize={10} fill="#333">
                  {payload.value}
                </text>
              </g>
            )
          }
          // axisLine={{ stroke: 'red', strokeWidth: 1, strokeOpacity: 1, strokeDasharray: '0 0' }}
          // tickFormatter={(t: any, i: number) => { console.log(t); return '';} }
        />
        <CartesianGrid
          stroke="#DFE2E3"
          strokeDasharray="4 4"
          vertical={false}
        />
        <YAxis hide={true} />
        {linesToDisplay.map(({ color, field }) => (
          <Line
            isAnimationActive={false}
            key={`${displayType}${field}`}
            dataKey={`${displayType}${field}`}
            stroke={color}
            fill={color}
            dot={{ strokeWidth: 1 }}
          >
            {/* {showLabels && (
              <LabelList
                dataKey={`${barDisplayType}${field}`}
                position="insideTop"
                offset={6}
                fill="white"
                fontSize={12}
                /*
                // @ts-ignore *
                formatter={value => barDisplayType === 'percentage' ? value < 11 ? '' : toPercentage(value) : toNumber(value)}
              />
            )} */}
          </Line>
        ))}
        <Legend
          verticalAlign="bottom"
          align="center"
          iconType="square"
          height={40}
          iconSize={10}
          formatter={(value) => value.replace(displayType, '')}
          wrapperStyle={{ fontSize: '12px' }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export {
  AnalyticsMultilineChart,
}